.scrollable-menu {
    height: auto;
    max-height: 300px;
    overflow-x: hidden;
}

.jumbotron {
	background-size: cover;
	width: 100%;
	position: relative;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 50px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 30px;
  color: black;
}
blockquote{
  font-size: 16px;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: .5em 10px;
  padding: 0 10px;
  quotes: "\201C""\201D""\2018""\2019";
  padding: 10px 20px;
  line-height: 1.4;
}
blockquote:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 20px;
  color: #ccc;
  font-size: 3em;
}
blockquote:after {
  visibility: hidden;
  content: close-quote;
}
blockquote p {
  display: inline;
}
